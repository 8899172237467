export const GET_BRANCHE = "GET_BRANCHE";
export const ADD_BRANCHE = "ADD_BRANCHE";
export const GET_REP = "GET_REP";
export const ADD_REP = "ADD_REP";
export const GET_ADS = "GET_ADS";
export const GET_DOOR = "GET_DOOR";
export const GET_MEMBER = "GET_MEMBER";
export const GET_MEMBER_DOORS = " GET_MEMBER_DOORS";
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_SLOTS = "GET_SLOTS";

export default {
  GET_BRANCHE,
  ADD_BRANCHE,
  GET_REP,
  ADD_REP,
  GET_ADS,
  GET_DOOR,
  GET_MEMBER,
  GET_MEMBER_DOORS,
  GET_PACKAGES,
  GET_SLOTS
};

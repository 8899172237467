import React from "react";
import Loadable from "react-loadable";
import { Spin } from "antd";
import LoadingIndicator from "../Common/LoadingIndicator/LoadingIndicator";

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 *
 * Each route lazy loads a component if its path matches the current path.
 */

const nestedRoutes = [
  {
    path: "/Dashboard/Branch",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Branch/Branch"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Users",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Users/Users"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Ads",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Ads/Ads"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/ProductsCategories",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/ProductsCategories"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Products",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/Products"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/HomeIamges",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/HomeIamges"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Requests",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/Requests"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/ContactUS",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/ContactUS"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Colors",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/Colors"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Sizes",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/Sizes"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Countries",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/Countries"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Cities",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/Cities"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/HoteOffers",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/Products/HoteOffers"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/Member",
    isAuth: true,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./components/RepOrganization/Member/Member"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/OrganizationStatistics",
    isAuth: true,
    allowedRoles: ["1"],
    component: Loadable({
      loader: () =>
        import("./components/OrganizationStatistics/OrganizationStatistics"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard/RepStatistics",
    isAuth: true,
    allowedRoles: ["2"],
    component: Loadable({
      loader: () =>
        import("./components/RepOrganization/RepStatistics/RepStatistics"),
      loading: () => <LoadingIndicator />,
    }),
  },
  {
    path: "/Dashboard",
    isAuth: true,
    allowedRoles: ["1"],
    component: Loadable({
      loader: () =>
        import("./components/OrganizationDashboard/OrganizationDashboard"),
      loading: () => <LoadingIndicator />,
    }),
  },
];

const routes = [
  {
    path: "/Dashboard",
    isAuth: true,
    nestedRoutes,
    allowedRoles: ["1", "2", "3"],
    component: Loadable({
      loader: () => import("./Dashboard"),
      loading: () => <LoadingIndicator />,
    }),
  },
];

export default routes;

export const ar = {
  auth: {
    changepassword: "تغير كلمة المرور",
    currentPassword: "كلمة المرور الحالية",
    NewPassword: "كلمة الجديدة",
    save: "حفظ",
    enterMobile: "يرجى إدخال رقم الموبيل",
    mobileMesg: "لقد أدخلت رقم خاطئ",
    mobileValidation: "رقم  الهاتف يجب ان يكون 11 رقم",
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    email: "بريد الكتروني",
    rememberMe: "تذكرني",
    signUp: "إنشاء حساب",
    Address: "العنوان",
    ChooseyourCountry: "اختر بلدك",
    ChooseyourCity: "اختر مدينتك",
    FirstName: "الاسم الاول",
    lastName: "الاسم الاخر",
    wrongformat: "تنسيق خاطئ",
    Password: "كلمة السر",
    passwordConfirm: "تأكيد كلمة السر",
    passwordMassage: "الرجاء ادخال كلمه السر",
    myacoount: "حسابى",
    thisIsRequeried: "هذا الحقل مطلوب",
    changephone: "تغير رقم الهاتف",
    changepassword: "تغير كلمة المرور",
    currentPassword: "كلمة المرور الحالية",
    NewPassword: "كلمة الجديدة",
    save: "حفظ",
    AddCategorie: "إضافة فئة",
    editCategorie: "تعديل فئة",
    AddCategorieEn: "اسم الفئة باللغة الإنجليزية",
    AddCategorieAr: "اسم الفئة باللغة العربية",
    required: "هذه الخانة مطلوبه",
    ContactUs: "تواصل معنا",
    name: "الاسم",
    email: "البريد الالكترونى",
    phoneNumber: "رقم الجوال",
    subject: "الموضوع",
    content: "الشرح",
    pending: "قيد الانتظار",
    done: "تم",
    delivered: "تم التوصيل",
    Deleted: "تم المسح",
    descriptionEnE: "تفاصيل الاعلان بالانجليزية",
    descriptionAr: "تفاصيل الاعلان بالعربية",
    SendMails: "ارسال الايميل",
    orderNo: "رقم الطلب",
    customerName: "اسم العميل",
    totalPrice: "اجمالى السعر",
    actions: "الافعال",
    Details: "التفاصيل",
    canceld: "ألغاء",
    movetoProcessing: " الانتقال إلى المعالجة",
    movetoShipped: "نقل إلى الشحن",
    movetoDeliverd: "تم التوصيل",
    Shipped: "تم الشحن",
    ProductName: "اسم المنتج",
    productPrice: "سعر المنتج",
    quantity: "الكمية",
    unitPrice: "سعر الوحدة",
    apptNo: "شقة رقم",
    cityName: "اسم المدينة",
    floor: "رقم الطابق",
    state: "المنطقة",
    streetName: "اسم الشارع",
    title: "الاسم",
    yes: "نعم",
    no: "لا",
    us: "أمريكى",
    eu: "الاتحاد الأوروبي",
    deleteItem: "هل أنت متأكد أنك تريد حذف هذا البند؟",
    deleteItemTitle: "مسح البند",
    titleinenglish: "العنوان بالانجليزية",
    titleinarabic: "العنوان بالعربية",
    descriptioninenglish: "التفاصيل بالانجليزية",
    descriptioninarabic: "التفاصيل بالعربية",
    overviewinenglish: "نظرة عامة بالانجليزية",
    overviewarabic: "نظرة عامة بالعربية",
    category: "الفئة",
    quantity: "الكمية",
    price: "السعر",
    isfreeshipping: "هل الشحن مجاني",
    customorders: "طلبات خاصة",
    phoneNumber: "رقم الهاتف",
    code: "كود",
    ProductName: "اسم المنتج",
    size: "المقاس",
    sizeType: "نوع المقاس",
    Countries: "الدول",
    Cities: "المدن",
    HomeIamges: "صور الرئيسية",
    sliderImages: "صور المعرض",
    categoriesImages: "صور الفئات",
    adsImage: "صورة الاعلان",
    changeprocess: "عملية التغيير",
    movethisitemtoprocessing: "انقل هذا العنصر إلى المعالجة",
    movethisitemtoshipping: "انقل هذا العنصر إلى الشحن",
    movethisitemtodeleiverd: "نقل هذا العنصر للتسليم",
    notes: "ملاحظات",
    forgetPassword: "نسيت كلمة المرور",
    Savechanges: "حفظ التغييرات",
    addNew: " أضافة  جديد",
    delete:"مسح",
    pleaseSelectTheColor:"برجاء اختيار اللون",
    PleaseSelectTheSize:"برجاء اختار المقاس",
    PleaseWriteAmount:"الرجاء كتابة الكمية المتوفرة"




  },
  header: {
    Home: "الرئيسية",
    About: "عن دورك",
    ContactUs: "تواصل معنا",
    BusinessOwners: " صاحب عمل؟",
    login: "تسجيل الدخول ",
    signUp: "إنشاء حساب",
    logout: "تسجيل الخروج",
    changepassword: "تغيير كلمة المرور",
    logotText: "هل أنت متأكد أنك تريد تسجيل الخروج ؟",
    Yes: "نعم",
    Cancel: "إلغاء",
  },
  errors: {
    Unauthorized: "غير مصرح",
    sorry401: "ناسف لا يمكن تنفيذ هذه العمليه",
    goToHome: " الرجوع للصفحه الرئيسية",
    sorry403: "نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.",
    Access: "غير مسموح بالدخول",
    notFound: "لم يتم الوصول للصفحه",
    error_text:
      "الصفحه التي تبحث عنها ربما تكون قد تم حذفهااو تم تغيير اسمها او غير متاحه حاليا",
    serverError: "خطا بالخادم",
    stay: "انتظر",
    stayMsg:
      "لقد انتهت مهلتك بسبب عدم النشاط. يرجى اختيار البقاء مسجّل الدخول أو تسجيل الخروج وإلا سيتم تسجيل خروجك تلقائيًا بعد دقيقة واحدة",
    inactivityMessage: "لقد تم تسجيل الخروج بسبب عدم النشاط",
  },
  Branchs: {
    edit: "تعديل",
    delete: "مسح",
    addBranch: "أضف فرع",
    save: "حفظ",
    Users: "المستخدمين",
    productsCategories: "فئات المنتجات",
    Hotoffers: "العروض",
    Requests: "الطلبات",
    Yes: "نعم",
    no: "لا",
    adddoor: "اضف دور",
    doornameArabic: "اسم الدور باللغة العربية",
    doornameenglish: "اسم الدور باللغة الانجليزية",
    status: "الحالة",
    active: "نشط",
    inactive: "غير نشط",
    editDoor: "تعديل دور",
    areyousuredeletethisdoor: "هل أنت متأكد من حذف هذا الدور؟",
    editMEmber: "تعديل بيانات العضو",
    AssignaDoortypeDoorname: "لادوار المستهدفة",
    memperfirst: "اسم العضو الاول",
    memberLastName: "اسم العضو الاخير",
    memberEmail: "البريد الالكترونى للعضو",
    areyousuredeletethismember: "هل أنت متأكد من حذف هذا العضو",
    NewCustomer: "عميل جديد",
    skip: "تخطى",
    next: "التالى",
    customerFirstName: "اسم العميل الاول",
    customerLastName: "اسم العميل الاخير ",
    back: "الرجوع",
    customerFullName: "الأسم بالكامل",
    Cancel: "إلغاء",
    BookedTime: "وقت الحجز",
    Showing: "الحضور",
    Notifying: "إشعار",
    Products: "منتجات",
    colorCode: "كود اللون",
    addColor: "اضافة لون",
    editColor: "تعديل لون",
    code: "كود",
    color: "كود",
    size: "المقاس",
    addSize: "اضافة مقاس ",
    editSize: "تعديل مقاس ",
    sizeNumber: "رقم المقاس",
    addProduct: "اضافة منتج",
    editProduct: "تعديل منتج",
    DiscountedPrice: "  % نسبة الخصم ",
    productdetailsEN: " تفاصيل المنتج بالانجليزية",
    productdetailsAR: " تفاصيل المنتج بالعربية",
    location: "الموقع",
    countryNameinenglish: "اسم البلد باللغة الإنجليزية",
    countryNameinarabic: "اسم البلد بالعربية",
    isDeleted: "تم المسح ",
    addcity: "اضافة مدينة ",
    cityNameinenglish: "اسم المدينة بالانجليزي",
    cityNameinarabic: "اسم المدينة بالعربي",
    ShippingFees: "  مصاريف الشحن",
    addcountry: "أضف الدولة",
    editcity: "تعديل المدينة",
    thisorderreatedon: "تم إنشاء هذا الطلب بتاريخ",
    contactinformation: "معلومات التواصل",
    customername: "اسم العميل",
    phoneNumber: "رقم الهاتف",
    email: "البريد الالكترونى",
    name: "الاسم",
  },
  Ads: {
    Advertisement: "اعلانات",
    AddAdvertisement: "أضف اعلانك",
    Duration: "المدة الزمنية",
    from: "من",
    to: "الى",
    targetbranch: "الفروع المستهدفة",
    editAdvertisement: " تعديل إعلان",
    Addname: "إضافة اسم",
    required: "هذا الحقل مطلوب",
    edit: "تعديل",
    delete: "مسح",
    adValidation:
      "يرجى تحميل الصورة بالتنسيق: JPEG و PNG و الابعاد: 375 * 120 بكسل",
    url: "رابط غير صحيح",
    areyousuredeletethisad: "هل أنت متأكد من حذف هذا الإعلان؟",
    Yes: "نعم",
    no: "لا",
    submit: "حفظ",
    wrongDimension: "ابعاد الصورة خاطئة",
    Start: "تاريخ البدء",
    end: "تاريخ النهاية",
    adslink: "رابط الإعلانات",
    ChooseyourSuitablePackage: "اختر الباقة المناسبة",
    PackagesText: "أنت تستمتع حاليًا الآن",
    yourhave: "انت لديك",
    PackageRemaining:
      "المتبقية حتى تنتهي الباقة الخاصة بك. إذا كنت ترغب في الحصول على مزايا إضافية ، يمكنك الترقية من هذه الباقة أدناه.",
    Scans: "المسح",
    Branches: "الفروع",
    membersdoor: "عضو/دور",
    Ads: "اعلانات",
    BusinessNeeds: "الباقات لا تناسب احتياجات عملك؟",
    customPackage:
      "ليس هناك أى مشكلة! يمكنك الآن تخصيص الباقة الخاصة بك وفقًا لاحتياجات عملك",
    CustomizePackage: "تخصيص الباقة",
  },
};

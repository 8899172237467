import React from 'react'
import Loadable from 'react-loadable'

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const routes = [
    {
        path: '/Error404',
        component: Loadable({
            loader: () => import('./components/Error404/Error404'),
            loading: () => (<h1>Loading component here</h1>)
        })
    },
    {
        path: '/Error403',
        component: Loadable({
            loader: () => import('./components/Error403/Error403'),
            loading: () => (<h1>Loading component here</h1>)
        })
    },
    {
        path: '/Error500',
        component: Loadable({
            loader: () => import('./components/Error500/Error500'),
            loading: () => (<h1>Loading component here</h1>)
        })
    },
]

export default routes
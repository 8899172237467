/**
 * Import action types here
 */
import {
  GET_BRANCHE,
  ADD_BRANCHE,
  GET_REP,
  GET_ADS,
  GET_DOOR,
  GET_MEMBER,
  GET_MEMBER_DOORS,
  GET_PACKAGES,
  GET_SLOTS,
} from "./action-types";

/**
 * Module internal initial state
 */
const initialState = {
  branchesList: [],
  listRep: [],
  listAds: [],
  listDoors: [],
  listMember: [],
  memberDoorsList: {},
  packagesList: [],
  slotsList: [],
};

/**
 * Checks dispatched actions and updates state accordingly
 * @param {Object} state
 * @param {Object} action
 * @returns {Function}
 */

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_BRANCHE:
      return listBranch(state, payload);
    case ADD_BRANCHE:
      return addBranch(state, payload);
    case GET_REP:
      return listRep(state, payload);
    case GET_ADS:
      return listAds(state, payload);
    case GET_DOOR:
      return listDoors(state, payload);
    case GET_MEMBER:
      return listMember(state, payload);
    case GET_MEMBER_DOORS:
      return listDoorsMember(state, payload);
    case GET_PACKAGES:
      return packagesList(state, payload);
    case GET_SLOTS:
      return slotsList(state, payload);

    default:
      return state;
  }
};

function listBranch(state, payload) {
  return {
    ...state,
    branchesList: payload.data.branches,
  };
}

function addBranch(state, payload) {
  return {
    ...state,
    branchesList: payload.data.branches,
  };
}

function listRep(state, payload) {
  return {
    ...state,
    repList: payload.data,
  };
}

function listAds(state, payload) {
  return {
    ...state,
    adsList: payload.data,
  };
}

function listDoors(state, payload) {
  return {
    ...state,
    doorsList: payload.data,
  };
}

function listMember(state, payload) {
  return {
    ...state,
    membersList: payload.data,
  };
}

function listDoorsMember(state, payload) {
  return {
    ...state,
    memberDoorsList: payload.data,
  };
}

function packagesList(state, payload) {
  return {
    ...state,
    packagesList: payload.data,
  };
}

function slotsList(state, payload) {
  return {
    ...state,
    slotsList: payload.data,
  };
}

export default reducer;

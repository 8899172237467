export const en = {
  auth: {
    changepassword: "change password",
    submit: "submit",
    currentPassword: "Current Password",
    NewPassword: "New Password",
    save: "Save",
    enterMobile: "Enter your mobile number ",
    mobileMesg: "you entered a wrong number ",
    mobileValidation: "Mobile Number Must Be 11 Digits",
    login: "login",
    logout: "Logout",
    Address: "Address",
    ConutryfromDropDownmenu: "Choose your Conutry from Drop Down menu",
    CityfromDropDownmenu: "Choose your city from Drop Down menu",
    EnteryourAdminData: "Enter your Admin Data",
    AddressinEnglish: "Address in English",
    AddressinArabic: "Address in Arabic",
    wrongformatpleaseaddressinEnglish:
      "wrong format, please address in English",
    wrongformatpleaseaddressinArabic: "wrong format, please address in Arabic",
    ChooseyourCountry: "Choose your Conutry",
    ChooseyourCity: "Choose your City",
    EnteryourOrganizationData: "Enter your Organization Data",
    email: "Email",
    mail_massage: "The input is not valid E-mail!",
    mail_req_massage: "Please input your E-mail!",
    FirstName: "First Name",
    lastName: "last Name",
    wrongformat: "wrong format",
    Password: "Password",
    passwordConfirm: "password Confirm",
    namemustbemin3characters: "name must be min 3 characters",
    namecantacceptspace: "name can't accept space",
    namecantacceptspecialcharacters: "name can't accept  special characters",
    namecantacceptnumbers: "name can't accept numbers",
    IagreetotheTermsandConditions: "I agree to the Terms and Conditions",
    password_placeholder: "Enter your password",
    plaeaseEnterMail:
      "Please Enter your email then click the button below to request another one.",
    password: "Password",
    mobile: "mobile",
    passwordmustbemincharacters: "password must be min 8 characters",
    passwordmustbemax20characters: "password must be max 20 characters",
    Passwordexpresion:
      "Password must contaian at lest one lower case letter, one upper case letter, one digit and one special character",
    forgetPassword: "Forget Password?",
    IagreetotheTermsandConditions: "I agree to the Terms and Conditions",
    Verify: "Verify your number",
    VerifyBtn: "Verify",
    ResendCode: "Resend Code",
    digitssenttoyourmobiletologin:
      "Please enter 6 digits sent to your mobile to login",
    yourcodewillbesentin: "your code will be sent in",
    passwordMassage: "Please input your password!",
    enterYourData: " Enter Your Data",
    repFirstName: "Rep First Name",
    repLastName: "Rep Last Name",
    memFirstName: "Member First Name",
    memLastName: "Member Last Name",
    myacoount: "My Account",
    thisIsRequeried: "This Field Is Required",
    changephone: "change phone",
    changepassword: "Change Password",
    submit: "submit",
    currentPassword: "Current Password",
    NewPassword: "New Password",
    save: "Save",
    wrongmobileNumber: "This is a wrong mobileNumber",
    Confirm: "Confirm",
    Reject: "Reject",
    mins: "mins",
    add: "Add",
    us: "US",
    eu: "EU",
    Service: "Service",
    Appointmentsperday: "Appointments (per day)",
    AppointmentDuration: "Appointment Duration (min)",
    Usersduration: "Users (per duration)",
    BookedTime: "Booked Time",
    CustomerName: "Customer Name ",
    cancelAppointment: "Cancel Appointment",
    startDate: "start Date",
    noQue: "You're not assigned any scheduled Doors",
    AddCategorie: "Add Category",
    editCategorie: "Edit Category",
    AddCategorieEn: "Category name in English ",
    AddCategorieAr: "Category name in Arabic",
    required: "This field is required",
    ContactUs: "Contact Us",
    name: "Name",
    email: "Email",
    phoneNumber: "Phone Number",
    subject: "Subject",
    content: "Description",
    pending: "pending",
    done: "Done",
    delivered: "Delivered",
    Deleted: "Deleted",
    descriptionEnE: "Advertisment description in english",
    descriptionAr: "Advertisment description in arabic",
    SendMails: "Send Mails",
    orderNo: "order Number",
    customerName: "customer Name",
    totalPrice: "Total Price",
    actions: "Actions",
    Details: "Details",
    canceld: "Cancel",
    movetoProcessing: " Move To Processing",
    movetoShipped: " Move To Shipped",
    movetoDeliverd: " Move To Deliverd",
    Shipped: "Shipped",
    ProductName: "Product Name",
    productPrice: "product Price",
    quantity: "quantity",
    unitPrice: "unit Price",
    apptNo: "apptment Number",
    cityName: "city Name",
    floor: "floor",
    state: "state",
    streetName: "street Name",
    title: "title",
    yes: "yes",
    no: "no",
    deleteItem: "Are you sure you want to delete this item?",
    deleteItemTitle: "Delete Item",
    titleinenglish: "Title in english",
    titleinarabic: "Title in arabic",
    descriptioninenglish: "Description in english",
    descriptioninarabic: "Description inarabic",
    overviewinenglish: "Overview in english",
    overviewarabic: "Overview in arabic",
    category: "category",
    quantity: "quantity",
    price: "price",
    isfreeshipping: "Is free shipping",
    customorders: "custom orders",
    phoneNumber: "Phone Number",
    code: "Code",
    ProductName: "Product Name",
    size: "Size",
    sizeType: "Size Type",
    Countries: "Countries",
    Cities: "Cities",
    HomeIamges: "Home Iamges",
    sliderImages: "Slider Images",
    categoriesImages: "Categories Images",
    adsImage: "Ads Image",
    changeprocess: "Change Process",
    movethisitemtoprocessing: "Move This Item To Processing",
    movethisitemtoshipping: "Move This Item To Shipping",
    movethisitemtodeleiverd: "Move This Item To Delivered",
    notes: "notes",
    forgetPassword: "Forget Password?",
    Savechanges: "Save changes",
    addNew:"Add New",
    delete:"Delete",
    pleaseSelectTheColor:"Please Select The Color",
    PleaseSelectTheSize:"Please Seelect The Size",
    PleaseWriteAmount:"Please write the available Quantity"

  },
  Ads: {
    Advertisement: "Advertisement",
    AddAdvertisement: "Add Advertisement ",
    Duration: "Duration",
    from: "From",
    to: "To",
    targetbranch: "Target branchs",
    editAdvertisement: "Edit Advertisement ",
    Addname: "Add name",
    required: "the field is required",
    edit: "Edit",
    delete: "Delete",
    adValidation:
      "Please upload the picture in Format: JPEG, PNG and Dimension: 375 * 120 px",
    url: "invalied URl",
    areyousuredeletethisad: "Are You Sure Delete This Ad?",
    Yes: "Yes",
    no: "No",
    submit: "submit",
    wrongDimension: "wrong Dimension",
    Start: "start Date",
    end: "End Date",
    adslink: "Ads Link",
    ChooseyourSuitablePackage: "Choose your Suitable Package",
    PackagesText: "You are currently now enjoying ",
    yourhave: "your have ",
    PackageRemaining:
      "remaining until your package expires. if you would like to have extra benefits, you can upgrade from these below Packages.",
    Scans: "Scans ",
    Branches: "Branches",
    membersdoor: "members/door",
    Ads: "Ads",
    BusinessNeeds: "Packages don't fit your Business Needs?",
    customPackage:
      "No Problem! Now you can customize your package according to your business needs",
    CustomizePackage: "Customize Package",
  },
  header: {
    Home: "Home",
    About: "About",
    ContactUs: " Contact Us",
    BusinessOwners: "Business Owners?",
    login: "Login",
    signUp: "Sign Up",
    logout: "Logout",
    logotText: "Are you sure you want to Logout ",
    Yes: "Yes",
    Cancel: "Cancel",
    changepassword: "change password",
  },
  errors: {
    goToHome: "Back Home",
    stay: "Stay",
    inactivityMessage: "You have been logged-out due to inactivity",
    stayMsg:
      " You are being timed-out due to inactivity .please choose to stay Signed in or Log-out otherwise  you will be logged outout automatically after 1 minute.",
  },
  Branchs: {
    Products: "Products",
    edit: "Edit",
    delete: "Delete",
    save: "Save",
    Users: "Users",
    Hotoffers: "Hot offers",
    Requests: "Requests",
    productsCategories: "products Categories",
    BranchNameinEnglish: "Branch Name in English",
    BranchNameMustMinCharacters: "Must Be Min 3 Characters",
    thisIsRequeried: "This Field Is Required",
    pleaseselectbranchname: "Please Select Branch Name",
    invaildEmail: "The input is not valid E-mail!",
    Search: "Search",
    Yes: "Yes",
    no: "No",
    status: "Status",
    active: "active",
    editMEmber: "Edit Member",
    memperfirst: "Member First Name",
    memberLastName: "Member Last Name",
    memberEmail: "Member Email",
    areyousuredeletethismember: "Are You Sure Delete This Member?",
    NewCustomer: "New Customer",
    back: "back",
    Cancel: "Cancel",
    colorCode: "Color Code",
    addColor: "Add color",
    editColor: "Edit color",
    addSize: "Add size",
    editSize: "Edit size",
    code: "Code",
    color: "Color",
    size: "Size",
    sizeNumber: "Size Number",
    addProduct: "Add Product",
    editProduct: "Edit Product",
    DiscountedPrice: "Discount Percentage %",
    productdetailsEN: "Product Details in English",
    productdetailsAR: "Product Details in Arabic",
    location: "Location",
    countryNameinenglish: "Country Name In English",
    countryNameinarabic: "Country Name In Arabic",
    isDeleted: "Is Deleted",
    addcity: "Add City",
    cityNameinenglish: "City Name In English",
    cityNameinarabic: "City Name In Arabic",
    ShippingFees: " Shipping Fees",
    addcountry: "Add Country",
    editcity: "Edit City",
    thisorderreatedon: "This Order Created On",
    contactinformation: "Contact Information",
    customername: "Customer Name",
    phoneNumber: "Phone number",
    email: "Email",
    name: "Name",
  },
};

import {
  AUTH_LOGIN,
  AUTH_CHECK,
  AUTH_LOGOUT,
  SET_LOCALE,
  UPDATE_CONNECTION_STATUS,
  AUTH_VERIFY,
  CHECK_VERIFY,
} from "./action-types";
import { en } from "../../../../resources/en";
import { ar } from "../../../../resources/ar";

/**
 * Module internal initial state
 */
const initialState = {
  isAuthenticated: false,
  isMobileVerfied: false,
  token: null,
  refreshToken: null,
  token_expiry: null,
  userRoles: null,
  currentLocale: null,
  currentResource: null,
  connectionStatus: false,
  verfiyToken: null,
  verfiyRefreshToken: null,
};

/**
 * Check dispatched action and update state accordingly
 * @param {Object} state
 * @param {Object} action
 * @returns {Function}
 */

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_VERIFY:
      return authverify(state, payload);
    case AUTH_CHECK:
      return checkAuth(state);
    case AUTH_LOGOUT:
      return logout(state);
    case SET_LOCALE:
      return setLocale(state, payload);
    case CHECK_VERIFY:
      return checkverify(state);
    case UPDATE_CONNECTION_STATUS:
      return updateConnectionStatus(state, payload);
    default:
      return state;
  }
};

function login(state, payload) {
  localStorage.setItem("access_token", payload.accessToken);
  localStorage.setItem("roles", payload.userType);
  localStorage.setItem("refreshToken", payload.refreshToken);

  return {
    ...state,
    isAuthenticated: true,
    token: payload.token,
    userRoles: payload.userType,
    refreshToken: payload.refreshToken,
    token_expiry: payload.token_expiry,
  };
}

function authverify(state, payload) {
  localStorage.setItem("verfiyToken", payload.accessToken);
  localStorage.setItem("verfiyRefreshToken", payload.refreshToken);
  return {
    ...state,
    verfiyToken: payload.accessToken,
    verfiyRefreshToken: payload.refreshToken,
    isMobileVerfied: true,
  };
}

function checkAuth(state) {
  state = Object.assign({}, state, {
    isAuthenticated:
      !!localStorage.getItem("access_token") ||
      !!window.sessionStorage.getItem("access_token"),
    token: localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : window.sessionStorage.getItem("access_token"),
    userRoles: localStorage.getItem("roles")
      ? localStorage.getItem("roles")
      : window.sessionStorage.getItem("roles"),
  });

  return state;
}

function checkverify(state) {
  state = Object.assign({}, state, {
    isMobileVerfied:
      !!localStorage.getItem("verfiyToken") ||
      !!window.sessionStorage.getItem("verfiyToken"),
    verfiyToken: localStorage.getItem("verfiyToken")
      ? localStorage.getItem("verfiyToken")
      : window.sessionStorage.getItem("verfiyToken"),
  });

  return state;
}

function logout(state) {
  window.sessionStorage.removeItem("access_token");
  window.sessionStorage.removeItem("roles");
  window.sessionStorage.removeItem("refreshToken");
  window.sessionStorage.removeItem("token_expiry");
  localStorage.removeItem("access_token");
  localStorage.removeItem("roles");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token_expiry");
  localStorage.removeItem("activeTap");
  return {
    ...state,
    isAuthenticated: false,
    token: null,
    userRoles: null,
  };
}

function setLocale(state, payload) {
  return {
    ...state,
    currentLocale: payload,
    currentResource: payload === "english" ? en : ar,
  };
}

function updateConnectionStatus(state, payload) {
  return {
    ...state,
    connectionStatus: payload,
  };
}

export default reducer;
